import { Component } from "react";
import Nav from "../../components/Menu";
import "./sass/PrivacyPolicy.scss";
import Footer from "../../components/Footer";

class PrivacyPolicy extends Component {
  render() {
    return (
      <div id="PrivacyPolicy">
        <Nav style="stylish" />

        <div className="content">
          <h1>Privacy Policy</h1>
          <p>Effective Date: 11/17/2024</p>

          <section>
            <h2>1. Introduction</h2>
            <p>
              Privacy Policy explains how we collect, use, disclose, and
              safeguard your information when you visit our website or use our
              mobile application (collectively, the "Platform"). Please read
              this Privacy Policy carefully. If you do not agree with our
              policies and practices, do not use the Platform.
            </p>
          </section>

          <section>
            <h2>2. Information We Collect</h2>
            <h3>2.1 Personal Information</h3>
            <ul>
              <li>Name</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>Date of birth</li>
              <li>Profile picture</li>
              <li>Location data</li>
            </ul>
            <h3>2.2 Non-Personal Information</h3>
            <ul>
              <li>Browser type and version</li>
              <li>Operating system</li>
              <li>Device information</li>
              <li>IP address</li>
              <li>
                Usage data (e.g., pages visited, time spent on the Platform)
              </li>
            </ul>
          </section>

          <section>
            <h2>3. How We Collect Information</h2>
            <h3>3.1 Directly from You</h3>
            <p>We collect information directly from you when you:</p>
            <ul>
              <li>Create an account</li>
              <li>Update your profile</li>
              <li>Post content</li>
              <li>Communicate with other users</li>
              <li>Participate in surveys or contests</li>
            </ul>
            <h3>3.2 Automatically</h3>
            <p>
              We collect information automatically through the use of cookies,
              web beacons, and other tracking technologies.
            </p>
            <h3>3.3 From Third Parties</h3>
            <p>
              We may receive information about you from third parties, such as
              social media platforms and advertising partners.
            </p>
          </section>

          <section>
            <h2>4. How We Use Your Information</h2>
            <ul>
              <li>Providing and maintaining the Platform</li>
              <li>Personalizing your experience</li>
              <li>Improving the Platform</li>
              <li>Communicating with you</li>
              <li>Enforcing our Terms of Service</li>
              <li>Complying with legal obligations</li>
            </ul>
          </section>

          <section>
            <h2>5. How We Share Your Information</h2>
            <ul>
              <li>Service providers and business partners</li>
              <li>Other users of the Platform</li>
              <li>Law enforcement agencies, as required by law</li>
              <li>
                Third parties in connection with a merger, acquisition, or sale
                of all or a portion of our assets
              </li>
            </ul>
          </section>

          <section>
            <h2>6. Your Rights Under GDPR</h2>
            <p>
              If you are a resident of the European Economic Area (EEA), you
              have certain rights under the General Data Protection Regulation
              (GDPR):
            </p>
            <ul>
              <li>Right to access your personal data</li>
              <li>Right to rectify inaccurate personal data</li>
              <li>Right to erase your personal data</li>
              <li>Right to restrict processing of your personal data</li>
              <li>Right to data portability</li>
              <li>Right to object to the processing of your personal data</li>
            </ul>
            <p>
              To exercise these rights, please contact us at hello@mentory.me
            </p>
          </section>

          <section>
            <h2>7. Data Security</h2>
            <p>
              We implement reasonable security measures to protect your personal
              information from unauthorized access, disclosure, alteration, and
              destruction. However, no method of transmission over the Internet
              or method of electronic storage is 100% secure.
            </p>
          </section>

          <section>
            <h2>8. Data Retention</h2>
            <p>
              We will retain your personal information only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use your information to the extent necessary to comply
              with our legal obligations, resolve disputes, and enforce our
              agreements.
            </p>
          </section>

          <section>
            <h2>9. International Data Transfers</h2>
            <p>
              Your information, including personal data, may be transferred to
              and maintained on servers located outside of your state, province,
              country, or other governmental jurisdiction where the data
              protection laws may differ from those of your jurisdiction.
            </p>
          </section>

          <section>
            <h2>10. Children's Privacy</h2>
            <p>
              Our Platform is not intended for use by children under the age of
              13. We do not knowingly collect personal information from children
              under 13. If you are a parent or guardian and you are aware that
              your child has provided us with personal data, please contact us.
            </p>
          </section>

          <section>
            <h2>11. Changes to This Privacy Policy</h2>
            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
              You are advised to review this Privacy Policy periodically for any
              changes.
            </p>
          </section>

          <section>
            <h2>12. Contact Us</h2>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us at:
            </p>
            <ul>
              <li>Email: hello@mentory.me</li>
              <li>Phone: +1 (425) 273 8192</li>
              <li>Whatsapp: +1 (425) 273 8192</li>
            </ul>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default PrivacyPolicy;
