import React, { Component } from "react";

import "./sass/BecomeaMentor.scss";

import Nav from "../../components/Menu";
import Footer from "../../components/Footer";
import SignUp from "../../components/SignUp";
import FAQ from "../../UI_Elements/FAQ";

import { withRouter } from "react-router";

//Asets
import svgApple from "../../assets/home/logos/apple.svg";
import svgHubspot from "../../assets/home/logos/hubspot.svg";
import svgAirbus from "../../assets/home/logos/airbus.svg";
import svgDekra from "../../assets/home/logos/dekra.svg";

import WhatIsinForYou_1 from "../../assets/BecomeaMentor/w1.png";
import WhatIsinForYou_2 from "../../assets/BecomeaMentor/w2.png";
import WhatIsinForYou_3 from "../../assets/BecomeaMentor/w3.png";

import step1 from "../../assets/BecomeaMentor/step1.png";
import step2 from "../../assets/BecomeaMentor/step2.png";
import step3 from "../../assets/BecomeaMentor/step3.png";

import bkgFAQ from "../../assets/BecomeaMentor/bkg_faq.svg";

import { Link } from "react-router-dom";

import GoogleOAuth from "../../inc/GoogleOAuth";

import Preloader from "../../UI_Elements/Preloader";

//Redux
import { compose } from "redux";
import { connect } from "react-redux";

class BecomeaMentor extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id="becomeaMentor">
        <Nav />

        <header>
          <h1 className="trl becomeMentor title">Become a mentor</h1>
          <p className="trl becomeMentor subtitle">
            Share what you already know and get paid for it.
          </p>

          {this.props.user ? (
            <Link to="/create-listing">
              <button className="btn large trl becomeMentor actionButton">
                Become a mentor
              </button>
            </Link>
          ) : (
            <GoogleOAuth
              success={() => this.props.history.push("/create-listing")}
            />
          )}
        </header>

        <section className="companies">
          <div className="content">
            <p className="trl becomeMentor companies title">
              Our mentors have experience in companies like
            </p>
            <div className="flex">
              <img src={svgApple} alt="" />
              <img src={svgHubspot} alt="" />
              <img src={svgAirbus} alt="" />
              <img src={svgDekra} alt="" />
            </div>
          </div>
        </section>

        <section className="WhatIsinForYou">
          <div className="content">
            <h2 className="trl becomeMentor WhatIsinForYou title">
              What’s in for you?
            </h2>

            <article className="flex w1">
              <img src={WhatIsinForYou_1} alt="" />
              <div>
                <p className="trl becomeMentor WhatIsinForYou earn subtitle">
                  Earn
                </p>
                <p className="trl becomeMentor whaIsinForYou textEarn">
                  Sign up and start booking up appointments for growth
                  mentorship calls. All you need to do is to log in on time and
                  to talk to someone. They’ll be the ones doing all of the work.
                  Your job is done as soon as the call is over.
                </p>
              </div>
            </article>

            <article className="flex w2">
              <div>
                <p className="trl becomeMentor WhatIsinForYou learn subtitle">
                  Learn
                </p>
                <p className="trl becomeMentor whaIsinForYou textLearn">
                  Pick and choose the clients you work with and offer advice and
                  insights across all sorts of different industries. It means
                  that every task is different and there’s always something new
                  for you to learn, no matter what you’re working on.
                </p>
              </div>
              <img src={WhatIsinForYou_2} alt="" />
            </article>

            <article className="flex w3">
              <img src={WhatIsinForYou_3} alt="" />
              <div>
                <p className="trl becomeMentor WhatIsinForYou connect subtitle">
                  Connect
                </p>
                <p className="trl becomeMentor whaIsinForYou textConnect">
                  Network with doers and thinkers and not just talkers. Becoming
                  a mentor is a great way to be exposed to a constant stream of
                  new disruptors who are all putting your advice into practice.
                </p>
              </div>
            </article>
          </div>
        </section>

        <section className="becomeaMentor3Steps">
          <div className="content">
            <h2 className="trl becomeMentor becomeaMentor3Steps title">
              Become a mentor in 3 steps
            </h2>

            <article className="flex">
              <div className="step1">
                <div>
                  <img src={step1} alt="" />
                </div>
                <h4 className="trl becomeMentor becomeaMentor3Steps title1">
                  Create your listing for free
                </h4>
                <p className="trl becomeMentor becomeaMentor3Steps text1">
                  Tell the community what can you help with without sign-up
                  charges.
                </p>
              </div>
              <div className="step2">
                <div>
                  <img src={step2} alt="" />
                </div>
                <h4 className="trl becomeMentor becomeaMentor3Steps title2">
                  Decide how you want to help
                </h4>
                <p className="trl becomeMentor becomeaMentor3Steps text2">
                  Choose your own schedule, prices, and connection preferenes.
                  We’re there to help along the way.
                </p>
              </div>
              <div className="step3">
                <div>
                  <img src={step3} alt="" />
                </div>
                <h4 className="trl becomeMentor becomeaMentor3Steps title3">
                  Welcome your first mentee
                </h4>
                <p className="trl becomeMentor becomeaMentor3Steps text3">
                  Once your listing is live, qualified mentees can reach out and
                  start making money for helping them out.
                </p>
              </div>
            </article>

            <Link className="startMentoringNow" to="/create-listing">
              <button className="btn large trl becomeMentor becomeaMentor3Steps actionButton">
                Start mentoring now
              </button>
            </Link>
          </div>
        </section>

        <section className="faq">
          <img src={bkgFAQ} alt="" />

          <div className="content">
            <h2 className="trl becomeMentor faq title">Popular questions</h2>

            <article>
              <div>
                <FAQ
                  value="1"
                  question="What’s Mentory about?"
                  answer="Mentory makes personal development available and affordable for all. Our mission is to provide more people with convenient access to personal mentors who can help other members of the community to lead a happier and healthier life. We are not intending to replace traditional learning. Many people prefer to spend countless hours enrolling in courses that they rarely complete, which is fine. We created Mentory so more people could benefit from the learnings and experience of great performers in almost every area of life in an intuitive and convenient way."
                />
                <FAQ
                  value="2"
                  question="How much does it cost?"
                  answer="There are no upfront fees for joining as a mentor. You’ll be charged a flat 10% fee of the price paid by your mentee only when you complete a session. In other words, you only pay if you make money."
                />
                <FAQ
                  value="3"
                  question="How much can I make per month?"
                  answer="This is up to you. Most of our mentors work with Mentory to earn supplemental income, and some can make thousands of euro per month. That depends on how many mentees you work with."
                />
                <FAQ
                  value="4"
                  question="How the mentor’s application process looks like?"
                  answer={
                    <React.Fragment>
                      Joining as a mentor is as simple as creating a listing via{" "}
                      <a className="primaryLink" href="#">
                        Become a Mentor
                      </a>
                      . Once you submit your listing, we will review it and if
                      it matches our guidelines (generally don’t be a jerk or a
                      liar), you’re in. Your listing shouldn’t take more than a
                      few hours to be live. If we have any questions about your
                      background or what you can offer to prospective mentees,
                      we’ll reach out to you.
                    </React.Fragment>
                  }
                />
              </div>
              <div>
                <FAQ
                  value="5"
                  question="How do I get paid?"
                  answer={
                    <span>
                      Currently we use PayPal as a platform to pay our mentors.
                      PayPal is supported in more than 200 countries.{" "}
                      <a
                        className="primaryLink"
                        target="_blank"
                        href="https://www.paypal.com/en/webapps/mpp/country-worldwide"
                      >
                        Check their website
                      </a>{" "}
                      to see if you country is available.
                    </span>
                  }
                />
                <FAQ
                  value="6"
                  question="How do I connect with mentees?"
                  answer="Use Mentory Connect, our secure built-in platform to connect with your mentee via chat, audio or video. All without leaving Mentory."
                />
                <FAQ
                  value="7"
                  question="How are sessions booked?"
                  answer="Every time a mentee books a session with you, they’ll propose you a day and date to connect. If the dates proposed don’t suit, just chat with your mentee to agree on a different one. Don’t risk your chance to help somebody and make some money just because the proposed date doesn’t work for you!"
                />
                <FAQ
                  value="8"
                  question="Is Mentory secure?"
                  answer="Our encrypted and authenticated infrastructure means that your data is always private and secure with us. We use industry standard security technologies such as HTTPS support, secure RTP, HTTP authentication, secure WebSockets and application level authentication using tokens. This ensures that your information is always safe, whether it’s financial, media, or messaging traffic. We have taken all of the steps to ensure that the Mentory is GDPR compliant."
                />
              </div>
            </article>

            <a
              href="https://mentory.freshdesk.com/support/tickets/new"
              target="_blank"
            >
              <button className="btn white trl becomeMentor faq btnAction">
                more questions?
              </button>
            </a>
          </div>
        </section>

        <SignUp />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};
export default compose(withRouter, connect(mapStateToProps))(BecomeaMentor);
