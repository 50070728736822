import React, { Component } from "react";
import LOG_IN_USER from "./LOG_IN_USER";
import axios from "axios";
import moment from "moment-timezone";
import Response from "../UI_Elements/Response";
import showResponse from "../UI_Elements/Response/showResponse";
import { withRouter } from "react-router";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

class GoogleOAuth extends Component {
  logindOrRegisterGoogleUserRequest = async (credentialResponse) => {
    await axios
      .post("/api/auth/google", {
        googleUserData: jwtDecode(credentialResponse.credential),
        userTimezone: moment.tz.guess(),
      })
      .then((res) => {
        LOG_IN_USER(res.data);
        if (this.props.success) this.props.success();
      })
      .catch((err) => {
        console.log("err ", err);
      });
  };

  render() {
    return (
      <div style={{ maxWidth: "400px", margin: "0 auto" }}>
        <GoogleOAuthProvider clientId="595661996835-2cqg422jjojh6spcho8g405j2ma86lnp.apps.googleusercontent.com">
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              showResponse("googleLoginLoading");
              this.logindOrRegisterGoogleUserRequest(credentialResponse);
            }}
            onError={() => {
              console.log("Login Failed");
            }}
          />
          <Response
            id="googleLoginLoading"
            type="success"
            title="Logged with Google"
            description="You're logged with google"
            btnText="Close"
            btnAction="close"
            callback={() =>
              this.ModalEndRateSession.current._openCloseModal(true)
            }
          />
        </GoogleOAuthProvider>
      </div>
    );
  }
}

/*
{BecomeaMentor ? (
   <GoogleLogin
      clientId="946203630997-kv47jkktg9fj3gh114007q3ldhgmfigg.apps.googleusercontent.com"
      render={(renderProps) => (
         <button
         onClick={renderProps.onClick}
         disabled={renderProps.disabled}
         className="btn large trl becomeMentor actionButton"
         >
         Become a mentor
         </button>
      )}
      s
      onSuccess={this.responseGoogle}
      onFailure={this.responseGoogle}
      cookiePolicy={"single_host_origin"}
   />
   ) : (
   <GoogleLogin
      clientId="946203630997-kv47jkktg9fj3gh114007q3ldhgmfigg.apps.googleusercontent.com"
      buttonText={label}
      onSuccess={this.responseGoogle}
      onFailure={this.responseGoogle}
      cookiePolicy={"single_host_origin"}
   />
)}
*/

export default withRouter(GoogleOAuth);
