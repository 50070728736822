import React, { Component } from "react";

//Pages
import Home from "./pages/Home/Home";
import FindMentor from "./pages/FindMentor/FindMentor";

import CreateListing from "./pages/CreateEditListing/CreateListing";
import EditListing from "./pages/CreateEditListing/EditListing";

import MyListings from "./pages/MyListings/MyListings";
import Listing from "./pages/Listing/Listing";
import Signup from "./pages/Signup/Signup";
import Login from "./pages/Login/Login";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ResetPasswordByEmail from "./pages/ResetPasswordByEmail/ResetPasswordByEmail";
import Settings from "./pages/Settings/Settings";
import Session from "./pages/Session/Session";
import BecomeaMentor from "./pages/BecomeaMentor/BecomeaMentor";
import Chat from "./pages/Chat/Chat";
import PublicProfile from "./pages/PublicProfile/PublicProfile";
import Page404 from "./pages/404/Page404";
import TheIdea from "./pages/TheIdea/TheIdea";
import Pricing from "./pages/Pricing/Pricing";
import Upgrade from "./pages/Upgrade/Upgrade";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";

//SASS
import "./sass/global.scss";

//React Router
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

//Redux
import Store from "./redux/store";
import { NOTIFICATIONS, SETTINGS } from "./redux/actions";

//Inc
import "./inc/Helpers";
import LOG_IN_USER from "./inc/LOG_IN_USER";
import withAuth from "./inc/withAuth";

//Axios config
import axios from "axios";
import {
  Socket,
  watchChangesStatusFromAllUsers,
  getCurrentStatusFromAllUsers,
} from "./inc/SockeIO.cliente";

import Preloader from "./UI_Elements/Preloader";

import Intercom from "./inc/Intercom";

class Mentory extends Component {
  constructor() {
    super();

    this.state = {
      preLoad: 0,
    };
  }

  componentDidMount() {
    //SocketIO
    watchChangesStatusFromAllUsers();
    getCurrentStatusFromAllUsers();

    //To production
    // if (process.env.NODE_ENV == "production") {
    //   if (
    //     window.location.hostname != "mentory.me" ||
    //     window.location.protocol != "https:"
    //   ) {
    //     window.location.href = `https://mentory.me${window.location.pathname}`;
    //   }
    // }

    //Chat notifications
    Socket.on("receive-msg", (NEW_MESSAGE) => {
      if (window.location.pathname == "/connect") return;

      Store.dispatch(NOTIFICATIONS(NEW_MESSAGE.chatID, "add"));
    });
    /******************************/

    axios
      .get("/api/checkToken")
      .then((res) => {
        LOG_IN_USER(res.data);
        this.setState({ preLoad: 1 });
      })
      .catch((err) => {
        this.setState({ preLoad: 1 });
        Store.dispatch(SETTINGS("userLogged", false));
      });
  }

  render() {
    const { preLoad } = this.state;

    if (preLoad == 0) return <Preloader page />;

    return (
      <Router>
        <div style={{ opacity: preLoad }}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/find" component={FindMentor} />

            <Route exact path="/signup" component={Signup} />
            <Route exact path="/login" component={Login} />

            <Route exact path="/reset-password" component={ResetPassword} />
            <Route
              exact
              path="/reset-password/:token"
              component={ResetPasswordByEmail}
            />

            <Route exact path="/become-a-mentor" component={BecomeaMentor} />
            <Route path="/me/:username" component={PublicProfile} />

            <Route exact path="/the-idea" component={TheIdea} />

            <Route exact path="/connect" component={withAuth(Chat)} />

            <Route
              exact
              path="/create-listing"
              component={withAuth(CreateListing)}
            />
            <Route
              exact
              path="/edit-listing/:slug"
              component={withAuth(EditListing)}
            />

            <Route exact path="/listing/:slug" component={Listing} />
            <Route exact path="/settings" component={withAuth(Settings)} />

            <Route exact path="/my-listings" component={withAuth(MyListings)} />
            <Route exact path="/sessions" component={withAuth(Session)} />

            <Route exact path="/pricing" component={Pricing} />
            <Route exact path="/upgrade" component={Upgrade} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />

            <Route path="*" component={Page404} />
            <Redirect from="*" to="/404" />
          </Switch>

          <Intercom user={Store.getState().user} />
        </div>
      </Router>
    );
  }
}

export default Mentory;
