import React, { Component } from "react";

import "./sass/MyListings.scss";

//Components
import Footer from "../../components/Footer";
import Nav from "../../components/Menu";
import MyListing from "../../components/ListingsCard/MyListing";
import MyFavorite from "../../components/ListingsCard/MyFavourite";
import Separator from "../../UI_Elements/Separator";

//Assets
import defaultUser from "../../assets/default.png";

import StarRatings from "../../UI_Elements/StarRatings";

//Redux
import { connect } from "react-redux";
import Store from "../../redux/store";
import { SETTINGS } from "../../redux/actions";

//React router
import { Link } from "react-router-dom";

import Preloader from "../../UI_Elements/Preloader";

class MyListings extends Component {
  constructor() {
    super();
  }

  render() {
    if (!this.props.user) return <Preloader page />;

    const {
      name,
      surname,
      location,
      bio,
      myListings,
      myFavourites,
      userPhoto,
      totReviews,
      rating,
      currency,
      username,
    } = this.props.user;
    const { userType, currentUsername } = this.props.settings;

    let validUserType;
    if (currentUsername == username) {
      validUserType = userType;
    } else {
      validUserType = "Mentee";
    }

    return (
      <div id="MyListings">
        <Nav style="stylish" />

        <section>
          <div className="content">
            <div className="Profile flex">
              <span
                style={{
                  backgroundImage: `url("${
                    userPhoto.trim() != "" ? userPhoto : defaultUser
                  }")`,
                }}
                className="photo"
              ></span>
              <div>
                <h4>
                  {name} {surname}
                </h4>
                {location ? (
                  <p className="location">{location}</p>
                ) : (
                  <p className="location">
                    <span className="trl myListings setYourLocation">
                      Set your location
                    </span>{" "}
                    <Link
                      className="primaryLink trl myListings here"
                      to="/settings"
                    >
                      here
                    </Link>
                    .
                  </p>
                )}

                <p className="trl myListings rating">Rating:</p>
                {totReviews > 0 ? (
                  <StarRatings rating={rating / totReviews} />
                ) : (
                  <small>
                    <i className="trl myListings noReviewsYet">
                      No reviews yet
                    </i>
                  </small>
                )}

                <div className="aboutMe">
                  <p className="trl myListings aboutMe">About me</p>
                  {bio ? (
                    <p>{bio}</p>
                  ) : (
                    <p>
                      <span className="trl myListings init">Add your bio</span>{" "}
                      <Link
                        className="primaryLink trl myListings linkHere"
                        to="/settings"
                      >
                        here
                      </Link>{" "}
                      <span className="trl myListings finalText">
                        or something interesting about you.
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>

            <Separator
              className="trl myListings myListingsSeparator"
              title="My listings"
            />

            <div className="myListingsContainer flex">
              {validUserType == "Mentor" ? (
                <React.Fragment>
                  {myListings.length > 0 ? (
                    myListings.map((listing, i) => {
                      return (
                        <MyListing
                          key={i}
                          listing={listing}
                          currency={currency}
                        />
                      );
                    })
                  ) : (
                    <div style={{ marginLeft: "11px" }}>
                      <p
                        className="trl myListings youHaveNotListingYet"
                        style={{ fontWeight: 600, marginBottom: "15px" }}
                      >
                        You haven’t created any listings yet. Become a mentor to
                        view them here.
                      </p>
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <div style={{ marginLeft: "11px" }}>
                  <p
                    className="trl myListings switchToaMentor"
                    style={{ fontWeight: 600, marginBottom: "15px" }}
                  >
                    Switch to a Mentor account to view your listings and start
                    making money.
                  </p>
                  {/* <button onClick={() => Store.dispatch( SETTINGS('userType', {userSwitch: 'Mentor', username}) )} className="btn trl myListings switchToMentor">Switch to Mentor</button> */}
                </div>
              )}
            </div>

            <Separator
              className="trl myListings favouritesSeparator"
              title="Favourites"
            />

            <div className="myFavouritesContainer flex">
              {validUserType == "Mentee" ? (
                myFavourites.length > 0 ? (
                  myFavourites.map((listingFavourite, i) => {
                    return (
                      <MyFavorite
                        key={i}
                        listingFavourite={listingFavourite}
                        currency={currency}
                      />
                    );
                  })
                ) : (
                  <div style={{ marginLeft: "11px" }}>
                    <p
                      className="trl myListings youDontHaveAnyFavouriteListingsYet"
                      style={{ fontWeight: 600, marginBottom: "15px" }}
                    >
                      You don’t have any favourite listings yet
                    </p>
                  </div>
                )
              ) : (
                <div style={{ marginLeft: "11px" }}>
                  <p
                    className="trl myListings switchToYourMenteeAccountToView"
                    style={{ fontWeight: 600, marginBottom: "15px" }}
                  >
                    Switch to your Mentee account to view and book your
                    favourite listings.
                  </p>
                  <button
                    onClick={() =>
                      Store.dispatch(
                        SETTINGS("userType", {
                          userSwitch: "Mentee",
                          username,
                        })
                      )
                    }
                    className="btn trl myListings switchToMentee"
                  >
                    Switch to Mentee
                  </button>
                </div>
              )}
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

const mapToStateProps = (state) => {
  return state;
};
export default connect(mapToStateProps)(MyListings);
